<div
	id="center"
	class="flex flex-col lg:flex-row lg:block justify-start lg:justify-center p-0 lg:p-0 overflow-y-auto"
>
	<slot />
</div>

<style lang="scss">
	#center {
		flex: inherit;
		height: 100%;
	}
</style>
